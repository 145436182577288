import * as React from 'react';
import {Link} from 'gatsby';
import * as style from '../css/Card.module.css';


function Card(info) {
  const dateElement = info.date ? <div className={style.cardDate}>{info.date}</div> : null;
  let title = <h2>{info.title}</h2>;
  if (info.url) {
    if (info.url.startsWith('/')) {
      title = <h2><Link to={info.url}>{info.title}</Link></h2>;
    } else {
      title = <h2><a href={info.url}>{info.title}</a></h2>;
    }
  }
  return (
    <article key={info.id} id={info.id} className={style.card}>
      {title}
      {dateElement}
      <div className={style.cardDescription}>{info.description}</div>
    </article>
  );
}

export default Card;
