import * as React from 'react';
import * as navigationStyle from '../css/navigation.module.css';
import * as appStyle from '../css/App.module.css';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';

function NavBar() {
  return (
    <nav className={navigationStyle.navBar}>
      <Link to="/" className={navigationStyle.logo}>Luis Da Silva</Link>
      <ul>
        <li><Link to="/blog">Articles</Link></li>
        <li>
          <Link to="/#projects" aria-haspopup="true">Projects</Link>
          <ul>
            <li><Link to="/#auto-mock">Auto-Mock</Link></li>
            <li><Link to="/#playground">Playground</Link></li>
          </ul>
        </li>
        <li><Link to="/#about">About</Link></li>
      </ul>
    </nav>
  );
}


function Layout({pageTitle, children}) {
  const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

  return (
    <div className={appStyle.App}>
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <NavBar />
      <main>
        {children}
      </main>
    </div>
  );
}


Layout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
