import * as React from 'react';
import Card from './card';
import {useStaticQuery, graphql} from 'gatsby';


function queryArticles() {
  const data = useStaticQuery(graphql`
        query {
            allMdx(
                sort: {
                    order: DESC, 
                    fields: frontmatter___date
                }, 
                filter: {}
            ) {
                nodes {
                    frontmatter {
                        title
                        date(formatString: "MMMM D, YYYY")
                        description
                        url
                    }
                    slug
                    id
                }
            }
        }
    `);

  const articles = data.allMdx.nodes.map((node) => {
    const element = node.frontmatter;
    element.id = node.id;
    element.key = node.slug;
    if (!element.url) {
      element.url = `/blog/${element.key}`;
    }
    return element;
  });

  return articles;
}


function Articles() {
  const allArticles = queryArticles();
  const cards = allArticles.map((project) => Card(project));
  return (
    <section id='articles'>
      <h1>Articles</h1>
      {cards}
    </section>
  );
}

export default Articles;
