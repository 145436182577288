import * as React from 'react';
import Articles from '../../components/articles';
import '../../css/App.css';
import Layout from '../../components/layout';

function App() {
  return (
    <Layout pageTitle="Blog" >
      <Articles />
    </Layout>
  );
}

export default App;
